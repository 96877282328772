<template lang="pug">
  .explanation-content
    .content(:class="kebabCase(explanationType)")
      .header-indent
      .item(
        v-for="item in items"
        :class="item.className"
      )
        .circle {{ item.number }}
        .label
          AppIcon.car-icon(
            v-if="item.className === 'available'"
            name="carSolid"
          )
          | {{ item.label }}
    .legend
      .legend-item(v-for="item in items")
        .circle {{ item.number }}
        .description {{ item.description }}
</template>

<script>
  import { kebabCase } from "lodash-es"

  export default {
    components: {
      AppIcon: () => import("@/components/elements/AppIcon")
    },

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },
      explanationType: String
    },

    methods: {
      kebabCase
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .explanation-content
    display: flex
    padding: 20px

    .circle
      align-items: center
      background-color: $default-red
      border-radius: 8px
      color: $default-white
      display: flex
      font-size: 0.75rem
      height: 16px
      justify-content: center
      position: absolute
      left: 0
      top: 0
      width: 16px

    .legend
      align-items: flex-start
      border-left: 1px solid $default-purple-light
      display: flex
      flex-direction: column
      justify-content: center
      margin-left: 15px
      padding-left: 15px
      width: auto

      &-item
        align-items: center
        display: flex
        justify-content: flex-start
        height: 20px

        .circle
          position: relative
          margin-right: 10px

    .content
      box-shadow: 3px 3px 5px -3px $default-gray-medium
      display: grid
      grid-template-columns: 240px 1fr

      &.manual-mode
        grid-template-areas: "header_indent date" "header_indent weekday" "car_class_name booked" "car_class_name available" "rental_period current_price"
        grid-template-rows: repeat(5, 1fr)

        .current-price
          .label
            align-items: center
            background-color: $default-purple-light
            border: 1px solid $default-purple
            border-radius: 5px 0 0 5px
            border-right: 0
            color: $default-purple
            display: flex
            height: 25px
            justify-content: center
            margin-left: 5px
            margin-right: 0
            padding-right: 5px
            width: 100%

      &.auto-price-mode
        grid-template-areas: "header_indent date" "header_indent weekday" "car_class_name booked" "car_class_name available" "car_class_name rank" "rental_period current_price" "rental_period calculated_price" "rental_period applying_price"
        grid-template-rows: repeat(8, 1fr)

      &.ai-mode
        grid-template-areas: "header_indent date" "header_indent weekday" "car_class_name booked" "car_class_name available" "rental_period current_price" "rental_period calculated_price" "rental_period applying_price"
        grid-template-rows: repeat(7, 1fr)

      &.auto-price-mode-autoset
        grid-template-areas: "header_indent date" "header_indent weekday" "car_class_name booked" "car_class_name available" "car_class_name rank" "rental_period current_price"
        grid-template-rows: repeat(6, 1fr)

      &.ai-mode-autoset
        grid-template-areas: "header_indent date" "header_indent weekday" "car_class_name booked" "car_class_name available" "rental_period current_price"
        grid-template-rows: repeat(5, 1fr)

    .header-indent
      grid-area: header_indent
    .car-class-name
      grid-area: car_class_name
    .rental-period
      grid-area: rental_period
    .date
      grid-area: date
    .weekday
      grid-area: weekday
    .booked
      grid-area: booked
    .available
      grid-area: available
    .rank
      grid-area: rank
    .current-price
      grid-area: current_price
    .calculated-price
      grid-area: calculated_price
    .applying-price
      grid-area: applying_price

    .car-class-name
      .label
        color: $default-purple
        font-size: 1rem
        font-weight: 600

    .rental-period
      background-color: $default-gray-light
      color: $default-black

      .label
        padding-left: 10px

    .car-class-name,
    .rental-period
      align-items: center
      display: flex
      position: relative

    .date,
    .weekday,
    .booked,
    .available,
    .rank,
    .current-price,
    .calculated-price,
    .applying-price
      align-items: center
      border-left: 1px solid $default-purple-light-line
      display: flex
      height: 30px
      justify-content: center
      position: relative
      width: 90px

    .date
      background-color: $th-background-color
      color: $default-purple

    .weekday
      color: $default-purple

    .available
      background-color: $default-gray-light
      color: $default-gray

      .car-icon
        margin-right: 10px

        ::v-deep
          .svg-fillable
            fill: $default-gray

    .current-price,
    .calculated-price,
    .applying-price
      background-color: $default-gray-light

    .calculated-price
      .label
        background-color: $default-orange
        border-radius: 5px
        padding: 2px 20px

    .applying-price
      .label
        background-color: $th-background-color
        border: 1px solid $default-purple-light-line
        border-radius: 5px
        color: $default-black
        padding: 1px 20px
</style>
